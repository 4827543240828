




































































import Vue from 'vue';
import Component, { mixins } from "vue-class-component";
import WpBaseComponent from 'web-platform-vuetify';
import GlobalService, {
    Initializer, IContext, IdentitySymbols, ICmsService,
    CmsSymbols, WpCorePrefixes, AppSettings, WidgetStyle, BaseFilter,
    IMetricsService, MetricsSymbols, WidgetType
} from 'web-platform-core-ui';

@Component
export default class WebSaleApp extends mixins(Vue, WpBaseComponent) {
    Settings!: AppSettings;
    Loading: boolean = true;
    IsInitializedError: boolean = true;
    IsOffline: boolean = false;
    Font?: string;
    OrganizationName: string = '';
    WidgetModal: boolean = false;
    OrganizationStatus: number = 0;
    SaleEnabled = true;

    get FontStyle(): string {
        return this.Font != null && this.Font != '' ? `font-family:${this.Font}` : ``;
    };

    OnlineStatusChange(isOnline: boolean): void {
        this.IsOffline = !isOnline;
    };

    async mounted() {
        window.addEventListener("hashchange", this.Refresh, false);

        let context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        let initResult = await Initializer.InitializeAsync(this.GlobalService);
        let metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);

        if (initResult.Success) {
            this.OrganizationStatus = initResult.Data.OrganizationStatus;
            this.SaleEnabled = initResult.Data.OrganizationStatus == 1 && initResult.Data.Status == 1
            this.IsInitializedError = false;
        } else {
            Error(`Initilization error: ${initResult.ErrorMessage}`);
            removeMainLoadingWrapper();
            this.Loading = false;
            this.SaleEnabled = false;
        }

        this.Settings = GlobalService.GetSettings<AppSettings>();
        this.WidgetModal = this.Settings.WidgetType == WidgetType.Modal;
        this.OrganizationName = this.Settings?.CurrentSite?.OrganizationName ?? '';
        await this.LoadStylesSettings();
        await metricsService.LoadCountersAsync();
        
        removeMainLoadingWrapper();
        this.Loading = false;
    };

    Refresh(): void {
        location.reload();
    };

    CloseWidget(): void {
        parent.postMessage({ status: 'wp-close-widget' }, '*');
    };

    async LoadStylesSettings() {
        let cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);
        let filter = new BaseFilter();
        filter.PlatformId = this.Settings.SiteId;
        let widgetStyle = await cmsService.GetEntitiesAsync<WidgetStyle>(WpCorePrefixes.WidgetStyle, filter, '');

        if (widgetStyle.Success && widgetStyle.Data != null && widgetStyle.Data.Entities != null &&
            widgetStyle.Data.Entities.length > 0) {
            if (widgetStyle.Data.Entities[0].Font != null && widgetStyle.Data.Entities[0].Font != '') {
                this.Font = widgetStyle.Data.Entities[0].Font;
                let htmlLink = document.createElement('link')
                htmlLink.rel = 'stylesheet';
                htmlLink.href = `https://fonts.googleapis.com/css?family=${this.Font}`;
                document.head.appendChild(htmlLink);
            };

            if (widgetStyle.Data.Entities[0].Primary != null && widgetStyle.Data.Entities[0].Primary != '')
                this.$vuetify.theme.currentTheme.primary = `#${widgetStyle.Data.Entities[0].Primary}`;
            if (widgetStyle.Data.Entities[0].Secondary != null && widgetStyle.Data.Entities[0].Secondary != '')
                this.$vuetify.theme.currentTheme.secondary = `#${widgetStyle.Data.Entities[0].Secondary}`;
            if (widgetStyle.Data.Entities[0].Accent != null && widgetStyle.Data.Entities[0].Accent != '')
                this.$vuetify.theme.currentTheme.accent = `#${widgetStyle.Data.Entities[0].Accent}`;
            if (widgetStyle.Data.Entities[0].Error != null && widgetStyle.Data.Entities[0].Error != '')
                this.$vuetify.theme.currentTheme.error = `#${widgetStyle.Data.Entities[0].Error}`;
            if (widgetStyle.Data.Entities[0].Info != null && widgetStyle.Data.Entities[0].Info != '')
                this.$vuetify.theme.currentTheme.info = `#${widgetStyle.Data.Entities[0].Info}`;
            if (widgetStyle.Data.Entities[0].Success != null && widgetStyle.Data.Entities[0].Success != '')
                this.$vuetify.theme.currentTheme.success = `#${widgetStyle.Data.Entities[0].Success}`;
            if (widgetStyle.Data.Entities[0].Warning != null && widgetStyle.Data.Entities[0].Warning != '')
                this.$vuetify.theme.currentTheme.warning = `#${widgetStyle.Data.Entities[0].Warning}`;
        };
    };
};
